<template>
  <KioskSlide title="Building Energy Usage" :sub-title="`Energy usage at ${kiosk.asset.siteName}`" :hide="hideSlide">
    <div class="p-5 border-bottom">
      <Spinner v-if="loading || loadingAction.getConsumption" />
      <div v-else class="row">
        <div class="col-md-5">
          <div class="font-w600 font-size-h2">Yesterday's Usage</div>
          <div class="font-w700 display-3 mb-4">{{ yesterdayUsage | numberFormat }} kWh</div>
          <PctDiff :usage="yesterdayUsage" :prev-usage="prevYesterdayUsage">Compared to same day last week</PctDiff>
          <!-- <div class="font-size-h4 font-w500 text-muted">Usage this week has been slightly higher so far compared to last week.</div> -->
        </div>
        <div class="col">
          <AssetWeeklyConsumption
            style="height: 325px"
            :period="weeklyGraph.period"
            :data="consumption.currentHH.data"
            units="kWh"
            weekdays
            compare
          />
        </div>
      </div>
    </div>
    <div class="p-5">
      <Spinner v-if="loading || loadingAction.getConsumption" />
      <div v-else class="row">
        <div class="col-md-5">
          <div class="font-w600 font-size-h2">Week Usage</div>
          <div class="font-w700 display-3 mb-4">{{ weekUsage | numberFormat }} kWh</div>
          <PctDiff :usage="weekUsage" :prev-usage="prevWeekUsage">Compared to previous week</PctDiff>
          <!-- <div class="font-size-h4 font-w500 text-muted">Usage this week has been slightly higher so far compared to last week.</div> -->
        </div>
        <div class="col">
          <AssetMonthConsumption style="height: 325px" :period="monthlyGraph.period" :data="consumption.currentHH.data" units="kWh" />
        </div>
      </div>
    </div>
  </KioskSlide>
</template>
<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import Spinner from '@/components/SpinnerLogo';
import KioskSlide from './KioskSlide';
import AssetWeeklyConsumption from '@/views/Machine/templates/charts/AssetWeeklyConsumption';
import AssetMonthConsumption from '@/views/Machine/templates/charts/AssetMonthConsumption';
import PctDiff from './components/PctDiff.vue';

export default {
  name: 'KioskEnergy',
  components: {
    AssetWeeklyConsumption,
    AssetMonthConsumption,
    KioskSlide,
    PctDiff,
    Spinner
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    hideSlide: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      yesterday: moment().subtract(2, 'days').startOf('day')
    };
  },
  computed: {
    ...mapGetters({
      kiosk: 'kioskLive/kiosk',
      asset: 'kioskLive/asset',
      consumption: 'kioskLive/consumption',
      loadingAction: 'kioskLive/loadingAction',
      loading: 'kioskLive/loading'
    }),
    yesterdayUsage() {
      const startDate = this.yesterday;

      const consumptionItems = this.consumption.currentHH.data.filter(item => moment(item.startDate).isSame(startDate, 'day'));

      return consumptionItems.reduce((acc, item) => acc + item.consumption, 0);
    },
    prevYesterdayUsage() {
      const startDate = this.yesterday.clone().subtract(1, 'week');

      const consumptionItems = this.consumption.currentHH.data.filter(item => moment(item.startDate).isSame(startDate, 'day'));

      return consumptionItems.reduce((acc, item) => acc + item.consumption, 0);
    },
    weekUsage() {
      const startDate = this.yesterday.clone().subtract(1, 'week');
      const endDate = this.yesterday;

      const consumptionItems = this.consumption.currentHH.data.filter(item => moment(item.startDate).isBetween(startDate, endDate, 'day', '[]'));

      return consumptionItems.reduce((acc, item) => acc + item.consumption, 0);
    },
    prevWeekUsage() {
      const startDate = this.yesterday.clone().subtract(2, 'weeks');
      const endDate = this.yesterday.clone().subtract(1, 'week');

      const consumptionItems = this.consumption.currentHH.data.filter(item => moment(item.startDate).isBetween(startDate, endDate, 'day', '[]'));

      return consumptionItems.reduce((acc, item) => acc + item.consumption, 0);
    },
    weeklyGraph() {
      return {
        period: {
          startDate: moment().subtract(1, 'week').startOf('day'),
          endDate: this.yesterday
        }
      };
    },
    monthlyGraph() {
      return {
        period: {
          startDate: moment().subtract(1, 'month').startOf('day'),
          endDate: this.yesterday
        }
      };
    }
  }
};
</script>
<style lang="scss"></style>
