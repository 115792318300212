var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('KioskSlide', {
    attrs: {
      "title": "Building Energy Usage",
      "sub-title": "Energy usage at ".concat(_vm.kiosk.asset.siteName),
      "hide": _vm.hideSlide
    }
  }, [_c('div', {
    staticClass: "p-5 border-bottom"
  }, [_vm.loading || _vm.loadingAction.getConsumption ? _c('Spinner') : _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-5"
  }, [_c('div', {
    staticClass: "font-w600 font-size-h2"
  }, [_vm._v("Yesterday's Usage")]), _c('div', {
    staticClass: "font-w700 display-3 mb-4"
  }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.yesterdayUsage)) + " kWh")]), _c('PctDiff', {
    attrs: {
      "usage": _vm.yesterdayUsage,
      "prev-usage": _vm.prevYesterdayUsage
    }
  }, [_vm._v("Compared to same day last week")])], 1), _c('div', {
    staticClass: "col"
  }, [_c('AssetWeeklyConsumption', {
    staticStyle: {
      "height": "325px"
    },
    attrs: {
      "period": _vm.weeklyGraph.period,
      "data": _vm.consumption.currentHH.data,
      "units": "kWh",
      "weekdays": "",
      "compare": ""
    }
  })], 1)])], 1), _c('div', {
    staticClass: "p-5"
  }, [_vm.loading || _vm.loadingAction.getConsumption ? _c('Spinner') : _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-5"
  }, [_c('div', {
    staticClass: "font-w600 font-size-h2"
  }, [_vm._v("Week Usage")]), _c('div', {
    staticClass: "font-w700 display-3 mb-4"
  }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.weekUsage)) + " kWh")]), _c('PctDiff', {
    attrs: {
      "usage": _vm.weekUsage,
      "prev-usage": _vm.prevWeekUsage
    }
  }, [_vm._v("Compared to previous week")])], 1), _c('div', {
    staticClass: "col"
  }, [_c('AssetMonthConsumption', {
    staticStyle: {
      "height": "325px"
    },
    attrs: {
      "period": _vm.monthlyGraph.period,
      "data": _vm.consumption.currentHH.data,
      "units": "kWh"
    }
  })], 1)])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }