<template>
  <div class="mb-5 font-size-lg">
    <span v-if="diff < 0" class="bg-success-lighter text-success px-2 py-2 rounded-md font-w700 mr-2"
      ><i class="fa fa-arrow-down"></i> {{ Math.abs(diff) }}%</span
    >
    <span v-else-if="diff > 0" class="bg-danger-lighter text-danger px-2 py-2 rounded-md font-w700 mr-2"
      ><i class="fa fa-arrow-up"></i> {{ Math.abs(diff) }}%</span
    >
    <span v-else class="bg-lightest text-muted px-2 py-2 rounded-md font-w700 mr-2"><i class="fa fa-minus"></i> {{ Math.abs(diff) }}%</span>
    <span class="text-muted"><slot>Compared to previous period</slot></span>
  </div>
</template>
<script>
export default {
  props: {
    usage: {
      type: Number,
      required: true
    },
    prevUsage: {
      type: Number,
      required: true
    }
  },
  computed: {
    diff() {
      return this.percentageDiff(this.usage, this.prevUsage);
    }
  },
  methods: {
    percentageDiff(usage, prevUsage) {
      if (prevUsage === 0 && usage !== 0) return 100;
      if (prevUsage === 0 && usage === 0) return 0;
      const pctDiff = (usage - prevUsage) / prevUsage;

      return Math.round(pctDiff * 100 * 10) / 10;
    }
  }
};
</script>
