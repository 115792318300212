<template>
  <div class="kiosk-wrapper">
    <div class="row kiosk-header">
      <div class="col-md-9">
        <h1 class="text-primary mb-1 fade" :class="{ in: !hide }">{{ title }}</h1>
        <h2 class="text-muted mb-0 fade" :class="{ in: !hide }">{{ subTitle }}</h2>
      </div>
      <div class="col-md-3 text-right">
        <div class="d-flex align-items-center justify-content-end">
          <img class="img-fluid" style="max-height: 100px" :src="kiosk.entity.companyLogo" />
          <div class="text-left ml-5 w-50">
            <p class="font-w500 text-muted mb-0">Powered by</p>
            <Logo />
          </div>
        </div>
      </div>
    </div>
    <div v-if="!loading" class="fade kiosk-body" :class="{ in: !hide }">
      <slot></slot>
    </div>
    <div v-else class="pt-5">
      <Spinner />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Logo from '@/components/Logo';
import Spinner from '@/components/SpinnerLogo';

export default {
  components: {
    Logo,
    Spinner
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    hide: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      kiosk: 'kioskLive/kiosk',
      loading: 'kioskLive/loading'
    })
  }
};
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght,YTLC@0,6..12,200..1000,486;1,6..12,200..1000,486&display=swap');

$primary: #ffffff;
$secondary: #4d9575;
$faded: #82979a;
$font: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, Noto Sans, sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$titleFont: 'Nunito Sans', -apple-system, Roboto, 'Helvetica Neue', Arial;

.kiosk-header {
  background: #f6f8fa;
  padding: 2rem;
  border-bottom: 2px solid #eceef0;
  height: 150px;

  h1 {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 2.8rem;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;
  }
}

.kiosk-wrapper {
  background: #ffffff;
  min-height: 100vh;
  overflow: hidden;
}

.fade {
  transition: all 0.75s ease;
  opacity: 0;

  &.in {
    opacity: 1;
  }
}

.kiosk-body > div {
  height: calc(50vh - 75px);
  margin-bottom: 1rem;
}
</style>
