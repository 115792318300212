var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-5 font-size-lg"
  }, [_vm.diff < 0 ? _c('span', {
    staticClass: "bg-success-lighter text-success px-2 py-2 rounded-md font-w700 mr-2"
  }, [_c('i', {
    staticClass: "fa fa-arrow-down"
  }), _vm._v(" " + _vm._s(Math.abs(_vm.diff)) + "%")]) : _vm.diff > 0 ? _c('span', {
    staticClass: "bg-danger-lighter text-danger px-2 py-2 rounded-md font-w700 mr-2"
  }, [_c('i', {
    staticClass: "fa fa-arrow-up"
  }), _vm._v(" " + _vm._s(Math.abs(_vm.diff)) + "%")]) : _c('span', {
    staticClass: "bg-lightest text-muted px-2 py-2 rounded-md font-w700 mr-2"
  }, [_c('i', {
    staticClass: "fa fa-minus"
  }), _vm._v(" " + _vm._s(Math.abs(_vm.diff)) + "%")]), _c('span', {
    staticClass: "text-muted"
  }, [_vm._t("default", [_vm._v("Compared to previous period")])], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }